/*
    Label styling
*/
.label-default {
  .label-variant(@label-default-bg);
}

.label-primary {
  .label-variant(@label-primary-bg);
}

.label-success {
  .label-variant(@label-success-bg);
}

.label-info {
  .label-variant(@label-info-bg);
}

.label-warning {
  .label-variant(@label-warning-bg);
}

.label-danger {
  .label-variant(@label-danger-bg);
}

.label-default-transparent {
  .label-variant-transparent(@label-default-bg);
}

.label-primary-transparent {
  .label-variant-transparent(@label-primary-bg);
}

.label-success-transparent {
  .label-variant-transparent(@label-success-bg);
}

.label-info-transparent {
  .label-variant-transparent(@label-info-bg);
}

.label-warning-transparent {
  .label-variant-transparent(@label-warning-bg);
}

.label-danger-transparent {
  .label-variant-transparent(@label-danger-bg);
}
