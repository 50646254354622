/*
    Modal styling
*/
.modal-backdrop {
  position: fixed;
  bottom: 0;
  &.fade.in {
    z-index: 1040;
  }
}
.modal-header {
  border-radius: 4px 4px 0 0;
}

.modal-header-success {
  background: @brand-success;
  .modal-title {
    color: @white;
  }
}

.modal-header-info {
  background: @brand-info;
  .modal-title {
    color: @white;
  }
}

.modal-header-warning {
  background: @brand-warning;
  .modal-title {
    color: @white;
  }
}

.modal-header-danger {
  background: @brand-danger;
  .modal-title {
    color: @white;
  }
}
