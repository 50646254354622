/*
    Sparkline styling
*/
.jqstooltip {
  width: auto !important;
  height: auto !important;
  border-radius: 2px;
  padding: 4px 6px !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  border: 0 !important;
}

/*
    JQVmap styling
*/
.jqvmap-zoomin,
.jqvmap-zoomout {
  background: mix(@brand-primary, @white, 75%);
}

/*
    Flot styling
*/
.tickLabel {
  font-size: 80%;
}
/*
    CK Editor styling
*/
.cke_chrome {
  border: 0 !important;
}
/*
    Summernote styling
*/
.note-editor {
  border: 0 !important;
}
/*
    Full calendar styling
*/
.fc-header {
  .fc-header-center {
    .fc-header-title {
      h2 {
        font-size: 20px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .fc-header td {
    display: block;
    text-align: center;
  }
}
