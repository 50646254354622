//
// Dropdown menus
// --------------------------------------------------

// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    color: @dropdown-link-hover-color;
    background-color: @dropdown-link-hover-bg;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: @dropdown-link-active-color;
    background-color: @dropdown-link-active-bg;
  }
}
