/*
    Pagination styling
*/

.pagination {
  > li {
    > a,
    > span {
      color: @pagination-color;
      background-color: @pagination-bg;
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      color: @pagination-hover-color;
      background-color: @pagination-hover-bg;
      border-color: @pagination-hover-border;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      color: @pagination-active-color;
      background-color: @pagination-active-bg;
      border-color: @pagination-active-border;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: @pagination-disabled-color;
      background-color: @pagination-disabled-bg;
      border-color: @pagination-disabled-border;
    }
  }
}
