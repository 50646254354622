/*
    Forms styling
*/

label {
  font-weight: 600;
}

.form-control {
  box-shadow: none;
  border: 1px solid @input-border;
}

.form-control:focus {
  box-shadow: inset 0 0 2px 2px @brand-primary-light;
  border: 1px solid @brand-primary-dark;
}
input[type='radio'],
input[type='radio']:hover,
input[type='radio']:focus {
  box-shadow: none;
}
.input-sm {
  .input-size(@input-height-small; @padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-small);
}

.input-lg {
  .input-size(@input-height-large; @padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-large);
}

// Feedback states
.has-success {
  .form-control-validation-theme(@state-success-text; @state-success-text; @state-success-bg);
}
.has-warning {
  .form-control-validation-theme(@state-warning-text; @state-warning-text; @state-warning-bg);
}
.has-error {
  .form-control-validation-theme(@state-danger-text; @state-danger-text; @state-danger-bg);
}

.input-group-addon {
  border: 1px solid @input-group-addon-border-color;
}

.input-group-btn > .btn-default {
  box-shadow: none !important;
  border-color: @input-group-addon-border-color;
}

.input-number {
  text-align: center;
}

.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 4px 6px;
  margin-bottom: 10px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 22px;

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto !important;
    max-width: inherit;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .tag {
    margin-right: 2px;
    color: white;

    [data-role='remove'] {
      margin-left: 8px;
      cursor: pointer;
      &:after {
        content: 'x';
        padding: 0px 2px;
      }
      &:hover {
        box-shadow:
          inset 0 1px 0 rgba(255, 255, 255, 0.2),
          0 1px 2px rgba(0, 0, 0, 0.05);
        &:active {
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }
      }
    }
  }
}
