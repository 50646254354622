/*
    Alerts and Callout styling
*/
.alert {
  padding: @alert-padding;
  margin-bottom: @line-height-computed;
  border-radius: @alert-border-radius;

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    // Specified for the h4 to prevent conflicts of changing @headings-color
    color: inherit;
  }
  // Provide class for links that match alerts
  .alert-link {
    font-weight: @alert-link-font-weight;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }
  > p + p {
    margin-top: 5px;
  }
  border: 0 !important;
}
.alert-success {
  .alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text);
}
.alert-info {
  .alert-variant(@alert-info-bg; @alert-info-border; @alert-info-text);
}
.alert-warning {
  .alert-variant(@alert-warning-bg; @alert-warning-border; @alert-warning-text);
}
.alert-danger {
  .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);
}

.callout {
  border: 0;
  border-left: 3px solid @gray;
  padding: @alert-padding;
  margin-bottom: 20px;
  h4 {
    margin-top: 0;
    color: inherit;
  }
  p,
  ul {
    margin-bottom: 0;
  }
}
.callout-success {
  .callout-variant(@brand-success-lighter; @alert-success-border; @alert-success-text);
}
.callout-info {
  .callout-variant(@brand-info-lighter; @alert-info-border; @alert-info-text);
}
.callout-warning {
  .callout-variant(@brand-warning-lighter; @alert-warning-border; @alert-warning-text);
}
.callout-danger {
  .callout-variant(@brand-danger-lighter; @alert-danger-border; @alert-danger-text);
}
