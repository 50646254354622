// Callouts
// -------------------------
.callout-variant(@background; @border; @text-color) {
  background-color: @background;
  border-color: @text-color;
  color: @text-color;

  hr {
    border-top-color: darken(@border, 5%);
  }
  .callout-link {
    color: darken(@text-color, 10%);
    font-weight: 700;
  }
}

// Transparent labels
// -------------------------
.label-variant-transparent(@color) {
  background-color: mix(@color, @white, 15%);
  border: 1px solid @color;
  color: mix(@color, @black, 80%);
  &[href] {
    &:hover,
    &:focus {
      background-color: darken(@color, 10%);
    }
  }
}

// Transparent badges
// -------------------------
.badge-variant-transparent(@color) {
  background-color: mix(@color, @white, 15%);
  border: 1px solid @color;
  color: mix(@color, @black, 80%);
  &[href] {
    &:hover,
    &:focus {
      background-color: darken(@color, 10%);
    }
  }
}

// Contextual backgrounds
// -------------------------
.bg-variant-theme(@color) {
  background-color: @color !important;
  a&:hover {
    background-color: darken(@color, 10%) !important;
  }
}

// Typography
// -------------------------
.text-emphasis-variant-theme(@color) {
  color: @color !important;
  a&:hover {
    color: darken(@color, 10%);
  }
}
// -------------------------
//Form control

.form-control-validation-theme(@text-color: #555; @border-color: #ccc; @background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    color: @text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: @border-color;
    /*.box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work*/
    box-shadow: none; //No box shadow. Creligent
    &:focus {
      border-color: darken(@border-color, 10%);
      /*@shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@border-color, 20%);
            .box-shadow(@shadow);*/
      box-shadow: inset 0 0 2px 2px mix(@border-color, @white, 30%); //No box shadow. Creligent
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: @text-color;
    border-color: @border-color;
    background-color: @background-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: @text-color;
  }
}
